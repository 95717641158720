import * as React from "react"

import Layout from "../components/layout"

const Login = () => (
  <Layout>
    <section className="body-font">
      <div className="container px-5 mx-auto">
        <div className="flex flex-col">
          <h1 className="mt-5 sm:w-2/5 text-gray-900 font-bold title-font text-2xl mb-2 sm:mb-0">
            About
          </h1>
        </div>
      </div>
    </section>
  </Layout>
)

export default Login
